import React from 'react'
import {Box, Typography} from '@mui/material'
import TeamBg from '../../images/team-bg.svg'

const Team = () => {
  return (
    <Box
      sx={{
        background: `url(${TeamBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: 'calc(100vh - 60px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 2,
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.6)',
          zIndex: -1
        },
        // pr: {md: 26, xs: 2},
        '& h2 div': {
          // fontFamily: "'Mate SC', serif !important"
        },
        '.large': {
          fontSize: {md: '36px', xs: '16px'},
          mt: '20px'
        },
        '.small': {
          fontSize: {md: '24px', xs: '12px'}
        },
        '.team': {
          fontSize: {md: '40px', xs: '20px'}
        }
      }}
    >
      <Typography variant='h2' display='flex' flexDirection={'column'} alignItems='center'>
        <Box className='team'>Team</Box>
        <Box className='large'>Allen</Box>
        <Box className='small'>Twi: @Allending_NS</Box>
        <Box className='large'>Aiko</Box>
        <Box className='small'>Ins: _pikakisugi</Box>
        <Box className='large'>Rebecca</Box>
        <Box className='small'>Ins: Rebecca_y_u</Box>
        <Box className='large'>Shui Peng</Box>
        <Box className='small'>Ins: PPSHUAI97</Box>
        <Box className='large'>Vector</Box>
        <Box className='small'>Ins: lee.vector</Box>
      </Typography>
    </Box>
  )
}

export default Team
