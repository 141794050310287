import React from 'react'
import {Box, Grid, InputBase, Typography} from '@mui/material'
import Demo1 from '../../images/demo-1.png'
import SearchIcon from '@mui/icons-material/Search'
import {useWindowSize} from 'react-use'

const Exhibits = () => {
  const {width} = useWindowSize()
  const ratio = width <= 1280 ? 1 : (width / 1280) * 1.2
  const spacingRatio = ratio === 1 ? 1 : ratio * 1.8

  return (
    <Box px={{md: 120 * ratio + 'px', xs: 3}}>
      <Box
        sx={{
          p: 2,
          mt: 6,
          display: 'flex',
          alignItems: 'center',
          background: 'rgba(244, 244, 244, 0.4)'
        }}
      >
        <SearchIcon sx={{color: '#333333'}} />
        <InputBase
          fullWidth={true}
          sx={{ml: 1, flex: 1}}
          placeholder='What NFTs are you looking for today?'
          inputProps={{'aria-label': 'search google maps'}}
        />
      </Box>
      <Box
        sx={{
          py: 6,
          background: 'linear-gradient(180deg, rgba(105, 105, 105, 0.4) 0%, rgba(2, 2, 2, 0) 100%)',
          '.btn': {
            p: '12px 13px',
            cursor: 'pointer'
          },
          '.unflex': {background: '#333333'},
          '.claim': {background: '#0065ff'},
          img: {
            display: 'block',
            width: '100%',
            height: 'auto'
          }
        }}
      >
        <Grid container>
          {Array.apply(null, Array(10)).map((_, i) => {
            return (
              <Grid
                item
                md={6}
                display='flex'
                key={`key-${i}`}
                mb={6}
                pr={i % 2 === 0 ? 25 * spacingRatio + 'px' : 0}
                pl={i % 2 === 1 ? 25 * spacingRatio + 'px' : 0}
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <img src={Demo1} alt='nft' />
                  </Grid>
                  <Grid item xs={6}>
                    <Box display='flex' flexDirection='column' height='100%'>
                      <Typography variant='h6' mb={3} mt={2}>
                        CryptoPunks#xxxxxx
                      </Typography>
                      <Typography variant='subtitle2'>
                        <Box>Session X: CryptoPunks Hall</Box>
                        <Box>Booth: 1,1</Box>
                        <Box>Size: 1*1</Box>
                        <Box>Booth Level: Silver</Box>
                      </Typography>
                      <Grid container mt='auto'>
                        <Grid item xs={6}>
                          <Box className='unflex btn'>Unflex</Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className='claim btn'>Claim</Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default Exhibits
