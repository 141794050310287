import React, {useState} from 'react'
import {Box, Grid, Link, Typography} from '@mui/material'
import Square from '../../components/style/Square'
import {useWindowSize} from 'react-use'
import {useParams} from 'react-router-dom'
import {HALL_META} from '../../constants'
import {getImage} from '../../constants/image'
import {SOCIAL_LINKS} from '../../constants/social'
import Demo1 from '../../images/demo-1.png'
import CloseIcon from '@mui/icons-material/Close'

const Hall = () => {
  const {id} = useParams<{id: string}>()
  const {width, height} = useWindowSize()
  const [position, setPosition] = useState<number[]>([-1, -1])
  const ratio = width / 1280
  const squareRatio = width <= 1280 ? 1 : width <= 1600 ? ratio * 0.9 : ratio * 0.8
  const navHeight = 50

  const selectPosition = (i: number, j: number) => {
    console.log(i)
    console.log(j)
    setPosition([i, j])
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      px={14}
      pt={8}
      sx={{
        background: `url(${HALL_META[Number(id)].bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: `calc(100vh - ${navHeight}px)`,
        position: 'relative',
        zIndex: 2,
        '&:before': {
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          zIndex: -2,
          left: 0,
          top: 0,
          position: 'absolute',
          bgcolor: 'rgba(0, 0 ,0, .35)'
        },
        '& .date': {
          fontSize: 13 * squareRatio + 'px'
        },
        '.body1': {
          fontSize: 16 * squareRatio + 'px'
        },
        '.body2': {
          fontSize: 14 * squareRatio + 'px'
        },
        '.title': {
          fontSize: 20 * squareRatio + 'px'
        }
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        sx={{
          img: {
            mr: 5,
            cursor: 'pointer'
          }
        }}
      >
        <Typography variant='h4' mr={10}>
          {HALL_META[Number(id)].name}
        </Typography>
        <Link href={SOCIAL_LINKS['org'].href} target='_blank'>
          <img src={getImage('org')} alt='org' width={26} />
        </Link>
        <Link href={SOCIAL_LINKS['discord'].href} target='_blank'>
          <img src={getImage('discord')} alt='discord' width={30} />
        </Link>
        <Link href={SOCIAL_LINKS['twitter'].href} target='_blank'>
          <img src={getImage('twitter')} alt='twitter' width={30} />
        </Link>
      </Box>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexGrow={1}
        flexDirection='column'
        mb={5}
        mt={-10}
      >
        <Box position='relative' width='100%'>
          <Box maxWidth='xl' display='flex' justifyContent='center'>
            <Box>
              {Array.apply(null, Array(10)).map((_, i: number) => (
                <Box display='flex'>
                  {Array.apply(null, Array(10)).map((_, j: number) => {
                    if (i > 3 && i < 6) {
                      if (j > 3 && j < 6) {
                        return (
                          <Square
                            key={`square-${i}-${j}`}
                            size={47 * squareRatio}
                            bg='rgba(0, 0, 0, .6)'
                            onClick={() => selectPosition(i, j)}
                          />
                        )
                      }
                    }
                    if (i > 1 && i < 8) {
                      if (j > 1 && j < 8) {
                        return (
                          <Square
                            key={`square-${i}-${j}`}
                            bg='rgba(168, 168, 168, .6)'
                            size={47 * squareRatio}
                            onClick={() => selectPosition(i, j)}
                          />
                        )
                      }
                    }
                    return (
                      <Square key={`square-${i}-${j}`} size={47 * squareRatio} onClick={() => selectPosition(i, j)} />
                    )
                  })}
                </Box>
              ))}
            </Box>
          </Box>
          <Box position='absolute' bottom={0} left={0}>
            Background artwork: Shuai Peng
          </Box>
        </Box>
      </Box>
      <Box
        position='fixed'
        width={`${width / 4.8}px`}
        height={`${height - navHeight}px`}
        bgcolor='rgba(0, 0, 0, .4)'
        top={navHeight}
        right={0}
        px={40 * ratio + 'px'}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        pt={18}
      >
        <Box
          position='absolute'
          right={20}
          top={20}
          sx={{
            cursor: 'pointer'
          }}
          onClick={() => setPosition([-1, -1])}
        >
          <CloseIcon />
        </Box>
        <Box
          sx={{
            height: 470 * squareRatio + 'px',
            mb: 16
          }}
        >
          {position[0] === -1 ? (
            <Box height='100%' display='flex' flexDirection='column'>
              <Box mt={-2}>
                <Typography className='body2'>Session: A01</Typography>
                <Typography className='title' mb={3}>
                  00<span className='date'>days</span>:00<span className='date'>hrs</span>:00
                  <span className='date'>mins</span>
                </Typography>
                <Typography className='body2' mb={4}>
                  Curator: Flexart
                </Typography>
                <Typography className='body2' mb={4}>
                  #Booth: 10 * 10
                </Typography>
                <Typography className='body2' mb={4}>
                  #Exhibits: /
                </Typography>
                <Typography className='body2'>Total Value of Collection: /</Typography>
              </Box>
              <Box display='flex' alignItems='flex-end' mb={3} mt='auto'>
                <Square size={36 * ratio} mr={4} />Ξ /
              </Box>
              <Box display='flex' alignItems='flex-end' mb={3}>
                <Square size={36 * ratio} bg='rgba(168, 168, 168, .6)' mr={4} />Ξ /
              </Box>
              <Box display='flex' alignItems='flex-end'>
                <Square size={36 * ratio} bg='rgba(0, 0, 0, .6)' mr={4} />Ξ /
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                '.btn': {
                  p: `20px 15px`,
                  cursor: 'pointer'
                },
                '.unflex': {background: '#333333'},
                '.claim': {background: '#0065ff'}
              }}
            >
              <Box
                sx={{
                  img: {
                    display: 'block',
                    width: '100%'
                  }
                }}
              >
                <img src={Demo1} alt='avatar' />
                <Typography variant='subtitle2' mt={6}>
                  ({position[0]}, {position[1]})
                </Typography>
                <Typography variant='h6' mb={2}>
                  CryptoPunks#xxxxxx
                </Typography>
                <Typography className='body1'>Owned by: @Owner name</Typography>
                <Typography className='body1'>Created by: @Creator name</Typography>
                <Typography className='body1'>Booth level: Silver</Typography>
                <Typography className='body1'>Size: 1*1</Typography>
              </Box>
              <Box position='absolute' width='100%' bottom={0} left={0}>
                <Grid container mt='auto'>
                  <Grid item xs={6}>
                    <Box className='unflex btn'>Unflex</Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className='claim btn'>Claim</Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Hall
