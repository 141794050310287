import React from 'react'
import {Box, BoxProps, StyledComponentProps} from '@mui/material'

// prettier-ignore
export interface SquareProps extends BoxProps, StyledComponentProps {
  bg?: string;
  size?: number
}

const Square = ({bg, size, ...other}: SquareProps) => {
  return (
    <Box
      {...other}
      sx={{
        width: size ? size + 'px' : '48px',
        height: size ? size + 'px' : '48px',
        border: '1px solid #e0e0e0',
        bgcolor: bg ? bg : 'rgba(255, 255, 255, .45)',
        cursor: 'pointer',
        '&:not(:last-child)': {
          borderRightWidth: 0
        },
        '&:hover': {
          // border: '1px solid #0000f0',
          background: 'rgba(125, 172, 255, 0.6)'
        }
      }}
    />
  )
}

export default Square
